import React, { useState, useRef } from 'react';
import logo from './logo.svg';
import landing_jora from './landing-page-jora.svg';
import venues_beta from './venues-beta.svg';
import manager from './manager.svg';
import artists_beta from './artists-beta.svg'
import './App.css';

export default function App()
{
  const [activeButton, setActiveButton] = useState(null);
  const stayTunedRef = useRef(null);
  const home = useRef(null);
  const artistsRef = useRef(null);
  // const about = useRef(null);
  const venuesRef = useRef(null);
  const managersRef = useRef(null);

  const handleBetaClick = (button) =>
  {
    setActiveButton(button);
    stayTunedRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const handleScrollTo = (ref) =>
  {
    const headerOffset = 80;
    const elementPosition = ref.current.getBoundingClientRect().top + window.scrollY;
    const offsetPosition = elementPosition - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    });
  };

  return (
    <div className="bg-white w-full h-full overflow-y-auto p-2 sm:p-5">
      {/* Navigation Buttons */}
      <div className='min-h-screen w-full flex flex-col'>
        <div className="fixed top-0 w-full text-xs sm:text-lg flex justify-between items-center py-4 bg-white">
          <button onClick={() => handleScrollTo(home)}>
            <img src={logo} alt='' className='w-[33px] sm:w-[44px] h-[42px] sm:h-[52px] pl-3' />
          </button>
          <div className="flex space-x-2 pr-5">
            <button onClick={() => handleScrollTo(venuesRef)} className="text-black hover:text-[#4F46E5] px-3 py-3 rounded-xl">
              Venues
            </button>
            <button onClick={() => handleScrollTo(artistsRef)} className="text-black hover:text-[#4F46E5] px-3 py-3 rounded-xl">
              Artists
            </button>
            <button onClick={() => handleScrollTo(managersRef)} className="text-black hover:text-[#4F46E5] px-3 py-3 rounded-xl">
              Managers/Agents
            </button>
          </div>
        </div>
        {/* Main Section */}
        <div ref={home} className="flex-grow flex justify-center items-center pt-20">
          <div className="flex flex-col md:flex-row justify-between items-left mg:items-center">
            <div className='w-full md:w-[50%]'>
              <div className='flex flex-col md:flex-row items-center justify-between'>
                <div className="ml-4 md:hidden">
                  <img src={landing_jora} alt="Small Design next to title" width={200} height={200} className="w-auto h-[200px] object-contain" />
                </div>
                <h1 className="text-6xl md:text-8xl font-semibold text-black">Jora</h1>
              </div>
              <p className="flex text-sm md:text-lg pr-3 text-center md:text-left text-[#64748B] mt-4">Welcome to Jora, an integrated marketplace for live music. Create a profile, upload your music portfolio, and browse away to find your next show, headliner, or collaborator with ease. With native show booking, ticketing, and social media integration, you can seamlessly plan events while gaining valuable insights through real-time analytics and data aggregation.
              </p>
              <div className="mt-8 flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4 justify-center md:justify-start">
                <button className="border border-black text-black py-2 px-6 md:py-3 rounded-xl" onClick={() => handleScrollTo(artistsRef)}>Learn More</button>
                <button className="bg-[#9790FF] text-white py-2 px-6 md:py-3 rounded-xl" onClick={() => handleScrollTo(stayTunedRef)}>Join Beta</button>
              </div>
            </div>
            <div className="mt-8 md:mt-0 hidden md:block">
              <img src={landing_jora} alt="Design" width={400} height={400} className="w-full h-auto object-contain" />
            </div>
          </div>
        </div>
      </div>
      {/* Description Section */}
      {/* <div className="py-12" ref={about}>
        <h2 className="text-3xl font-bold text-left">Your All-in-One Hub for Live Music Collaboration</h2>
        <p className="text-lg text-left mt-4">
          Welcome to Jora, an integrated marketplace for live music. Create a profile, upload your music portfolio, and browse away to find your next show, headliner, or collaborator with ease. With native show booking, ticketing, and social media integration, you can seamlessly plan events while gaining valuable insights through real-time analytics and data aggregation.
        </p>
      </div> */}
      {/* 3 Sections (stacked) */}
      <div className="py-12 space-y-8">
        {/* Section 2 - Venues */}
        <div ref={artistsRef} className="bg-[#4F46E51A] p-8 rounded-xl">
          <div className="flex flex-col md:flex-row justify-between items-center md:items-start space-y-4 md:space-y-0">
            <div className="md:w-1/2">
              <img src={artists_beta} alt="Artists Beta" className="w-auto h-auto max-w-[200px] sm:max-w-[300px] md:max-w-[400px] max-h-[200px] sm:max-h-[300px] md:max-h-[400px] object-contain" />
            </div>
            <div className="text-center md:text-right md:w-1/2">
              <h3 className="text-xl font-bold">Artists Beta</h3>
              <p className="mt-4">
              As an artist part of our beta test, you can build your EPK and manage all your cold call emails in one place. For artists in the Los Angeles (CA), Riverside (CA), and Boulder (CO) areas, participating in our beta, exclusive show opportunities will be made available to apply for in the Jora feed. More locations and event management features are to follow soon. Join the beta sign-up list today to test us out and have the opportunity to interact with our team as we build the best possible community platform for you.
              </p>
              <button
                onClick={() => handleBetaClick('Artist Beta')}
                className="bg-[#9790FF] text-white px-6 py-3 mt-4 rounded-xl"
              >
                Join Beta
              </button>
            </div>
          </div>
        </div>
        <div ref={venuesRef} className="bg-[#4F46E51A] p-8 rounded-xl">
          <div className="flex flex-col-reverse md:flex-row justify-between items-center md:items-start space-y-4 md:space-y-0">
            <div className="text-center md:text-left md:w-1/2">
              <h3 className="text-xl font-bold">Venues Beta</h3>
              <p className="mt-4">
              As a venue part of our beta test, you will be able to create show postings that reach target artists who meet the requirements of your show (genre, location, etc.). A native calendar feature will help keep track of scheduling and event analytics are to follow soon. Join the beta sign-up list today to test us out and have the opportunity to interact with our team as we build the best possible community platform for you.
              </p>
              <button
                onClick={() => handleBetaClick('Venue Beta')}
                className="bg-[#9790FF] text-white px-6 py-3 mt-4 rounded-xl"
              >
                Join Beta
              </button>
            </div>
            <div className="flex md:w-1/2 md:justify-end">
              <img
                src={venues_beta }
                alt="Venue Beta"
                className="w-auto  h-auto max-w-[200px] sm:max-w-[300px] md:max-w-[400px] max-h-[200px] sm:max-h-[300px] md:max-h-[400px] object-contain"
              />
            </div>
          </div>
        </div>

        {/* Section 3 - Manager/Agent */}
        <div ref={managersRef} className="bg-[#4F46E51A] p-8 rounded-xl">
          <div className="flex flex-col md:flex-row justify-between items-center md:items-start space-y-4 md:space-y-0">
            <div className="md:w-1/2">
              <img src={manager} alt="Manager/Agent" className="w-auto  h-auto max-w-[200px] sm:max-w-[300px] md:max-w-[400px] max-h-[200px] sm:max-h-[300px] md:max-h-[400px] object-contain" />
            </div>
            <div className="md:w-1/2 text-center md:text-right">
              <h3 className="text-xl font-bold">Manager/Agent</h3>
              <p className="mt-4">
                As we aim to unify and serve the entire live music community, we are actively seeking input from managers, booking agents, and industry professionals to shape the features that matter most to you. By joining our beta, you'll have a direct line of communication with our team to ensure we develop tools tailored to your specific needs. Be part of the conversation and help us create a platform that supports the entire live music ecosystem.
              </p>
              <button
                onClick={() => handleBetaClick('Manager/Agent')}
                className="bg-[#9790FF] text-white px-6 py-3 mt-4 rounded-xl"
              >
                Join Beta
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Subscription Section */}
      <div ref={stayTunedRef} className="text-center py-24">
        <h2 className="text-2xl font-bold">Stay Tuned</h2>
        <p className="mt-4 text-lg text-[#64748B]">
          Be the first to experience Jora! Subscribe to our beta and join the movement to transform live music collaboration. Get early access and help shape the future of the platform.
        </p>
        <form id='form'
          name='subscribe'
          method='post'
        >
          {/* Netlify form handling hidden field */}
          <input type='hidden' name='form-name' value='subscribe' />
          <input type='hidden' name='beta-type' value={activeButton} />
          <div className="mt-8 flex flex-col space-y-4 sm:space-y-0 sm:flex-row justify-center sm:space-x-6 px-12">
            <button
              type='button'
              onClick={() => setActiveButton('Artist Beta')}
              className={`px-6 py-3 rounded-xl border ${activeButton === 'Artist Beta' ? 'bg-[#b8c0ff] border-transparent shadow-[inset_0_4px_8px_rgba(0,0,0,0.6)]' : 'bg-transparent border-black'}`}
            >
              Artist Beta
            </button>
            <button
              type='button'
              onClick={() => setActiveButton('Venue Beta')}
              className={`px-6 py-3 rounded-xl border ${activeButton === 'Venue Beta' ? 'bg-[#b8c0ff] border-transparent shadow-[inset_0_4px_8px_rgba(0,0,0,0.6)]' : 'bg-transparent border-black'}`}
            >
              Venue Beta
            </button>
            <button
              type='button'
              onClick={() => setActiveButton('Manager/Agent')}
              className={`px-6 py-3 rounded-xl border ${activeButton === 'Manager/Agent' ? 'bg-[#b8c0ff] border-transparent shadow-[inset_0_4px_8px_rgba(0,0,0,0.6)]' : 'bg-transparent border-black'}`}
            >
              Manager/Agent
            </button>
          </div>
          <div className="mt-8">
            <input
              placeholder='Enter your email'
              type='email'
              id='email'
              name='email'
              required
              className="bg-[#f6f5fd] border border-black px-4 py-2 text-black rounded-xl text-center"
            />
          </div>
          <button type='submit' className="bg-[#9790FF] text-white px-6 py-3 mt-4 rounded-xl">Join Beta</button>
        </form>
      </div>
    </div>
  );
};
